import HomePage from './components/Homepage/HomePage'
import './App.css';

function App() {
  return (
    <HomePage></HomePage>
  );
}

export default App;
